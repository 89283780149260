import React, { Component } from "react";
import {
  Layout,
  Card,
  Button,
  Select,
  Col,
  Row,
  Icon,
  notification,
  Drawer,
  Radio,
} from "antd";
import logo from "../static/images/verkoopnou-logo.png";
import silverCar from "../static/images/silver-car.png";
import silverCarWheel from "../static/images/silver-car-wheel.webp";
import southAfricaFlag from "../static/images/south-africa-flag.jpg";
import soldVehicles from "../static/images/sold-vehicles-pic.png";
import statPic from "../static/images/stats-pic.png";
import moneyTransfer from "../static/images/money-transfer-pic.png";
import dotLine from "../static/images/dot-line.png";
import circle1 from "../static/images/circle-1.png";
import circle2 from "../static/images/circle-2.png";
import circle3 from "../static/images/circle-3.png";
import circleBig from "../static/images/circle-big.png";
import PDF from "../Verkoopnou_Website_Terms_and_Conditions.pdf";
import "./sales-screen.css";
import Fade from "react-reveal/Fade";

import Flip from "react-reveal/Flip";
import RubberBand from "react-reveal/RubberBand";
import { Link } from "react-router-dom";
const { Header, Content, Footer } = Layout;

const { Option } = Select;

const TopHeader = () => {
  return (
    <Header className="header-home" style={{ maxWidth: "10em" }}>
      <a href="/">
        <img src={logo} alt="Logo" className="verkoopnou-logo" />
      </a>
      <h1 className="verkoopnou-logo-text">verkoopnou.co.za </h1>
      <div></div>
      <div className="header-links">
        <Link to="/">
          <a
            style={{
              paddingLeft: "1.5em",
              fontWeight: 600,

              textDecoration: "underline",
            }}
          >
            {window.i18n.translate("Home")}
          </a>{" "}
        </Link>
        <a href="#middle">
          <a style={{ paddingLeft: "1.5em" }}>
            {" "}
            {window.i18n.translate("HowItWorks")}
          </a>
        </a>
        <a
          style={{ paddingLeft: "1.5em" }}
          href="https://www.verkoopnou.co.za/app/signup?type=dealer"
        >
          {window.i18n.translate("BeADealer")}
        </a>{" "}
        <Link to="/contact-us">
          <a style={{ paddingLeft: "1.5em" }}>
            {window.i18n.translate("ContactUs")}
          </a>
        </Link>
      </div>{" "}
      <a href="https://www.verkoopnou.co.za/app/signin?type=customer">
        <Button
          id="loginButtons"
          className="header-button-customer"
          type="primary"
        >
          {window.i18n.translate("CustomerLogin")}
        </Button>
      </a>
      <a href="https://www.verkoopnou.co.za/app/signin?type=dealer">
        <Button
          style={{
            background: "white",
            color: "#00B4BC",
          }}
          id="loginButtons"
          className="header-button-dealer"
          type="primary"
        >
          {window.i18n.translate("DealerLogin")}
        </Button>
      </a>
    </Header>
  );
};
const BottomFooter = () => {
  return (
    <Footer className="bottom-footer">
      <img src={logo} alt="Logo" className="footer-logo" />
      <div className="footer-links">
        <a href="/">
          <a style={{ color: " #00888e" }}>{window.i18n.translate("Home")}</a>
        </a>
        <br />
        <a href="#middle">
          <a style={{ color: " #00888e" }}>
            {window.i18n.translate("HowItWorks")}
          </a>
        </a>
        <br />
        <a
          style={{ color: " #00888e" }}
          href="https://www.verkoopnou.co.za/app/signup?type=dealer"
        >
          {window.i18n.translate("BeADealer")}
        </a>{" "}
        <br />{" "}
        <Link to="/contact-us">
          <a style={{ color: " #00888e" }}>
            {window.i18n.translate("ContactUs")}
          </a>
        </Link>
      </div>
      <div className="footer-links-div">
        {/*  <a style={{ color: " #00888e" }}> {window.i18n.translate("Help")}</a>
        <br />*/}
        <a
          href="./VKN DEALER TERME AFR Finaal.docx"
          download
          style={{ color: "#00888e" }}
        >
          {window.i18n.translate("TermsConditions")}
        </a>
        <br />
        <a style={{ color: " #00888e" }}>
          {/*    {window.i18n.translate("PrivacyPolicy")}*/}
        </a>
        <br />
      </div>
      <div className="footer-text">
        <p style={{ color: "#00888E" }}>
          13 Joe Slovo Rd,CBD
          <br />
          Klerksdorp
          <br /> 2570
          <br /> <br /> 060 VERKOOP ( 837 5667 )
          <br /> <br />
          info@verkoopnou.co.za
        </p>{" "}
        <a href="https://www.facebook.com/verkoopnou">
          <Icon
            className="facebook-icon"
            type="facebook"
            theme="filled"
            style={{ paddingRight: "0.5em", fontSize: "2em", color: "#00888E" }}
            url="https://www.facebook.com/verkoopnou/"
          />
        </a>
      </div>
      <div style={{ textAlign: "center" }} className="footer-watermark">
        <p style={{ color: "grey" }}>
          © 2020 VerkoopNou | {window.i18n.translate("Powered")}{" "}
          <a href="https://www.venturepoint.co.za" style={{ color: "grey" }}>
            {" "}
            Venturepoint Consulting Services
          </a>
          (PTY) LTD
        </p>
      </div>
    </Footer>
  );
};

class SalesScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      placement: "left",
      years: [],
      makes: [],
      models: [],
      year: undefined,
      make: undefined,
      model: undefined,
    };
  }

  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };
  selectModel = (model) => {
    this.setState({ model: model });
  };
  selectMake = (make) => {
    fetch(
      "https://www.vcsappcloud.com/verkoopnou/api/cddata/findmodels?make=" +
        make +
        "&year=" +
        this.state.year,
      {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
      }
    ).then(async (res) => {
      if (!res.ok)
        notification.error({
          message: "Error retrieving makes",
        });
      const result = await res.json();
      this.setState({ models: result.models, make: make, model: undefined });
    });
  };
  selectYear = (year) => {
    fetch(
      "https://www.vcsappcloud.com/verkoopnou/api/cddata/findmakes?year=" +
        year,
      {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
      }
    ).then(async (res) => {
      if (!res.ok)
        notification.error({
          message: "Error retrieving makes",
        });
      const result = await res.json();
      this.setState({
        makes: result.makes,
        year: year,
        make: undefined,
        model: undefined,
      });
    });
  };
  componentWillMount = () => {
    fetch("https://www.vcsappcloud.com/verkoopnou/api/cddata/findyears", {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
    }).then(async (res) => {
      if (!res.ok)
        notification.error({
          message: "Error retrieving years",
        });
      const result = await res.json();
      this.setState({ years: result.years });
    });
  };
  handleLocaleSwitch = (locale) => {
    this.setState({ loading: true });
    localStorage.locale = locale.target.value;
    this.setState({ loading: false });
  };
  render() {
    return (
      <React.Fragment>
        <div
          style={{
            top: "0em",
            border: "0px solid #8888882c",
            boxShadow: "none",
            width: "100vw",
            zIndex: 99,
            height: "105vh",

            maxHeight: "60em",
            minHeight: "57em",
          }}
        >
          {" "}
          <div
            style={{
              margin: "auto",
              top: "0em",
              maxWidth: "105em",
            }}
          >
            <Icon
              className="burger-menu"
              style={{
                visibility: "hidden",
                fontSize: "2em",
                position: "absolute",
                right: "0.7em",
                top: "0.7em",
              }}
              type="menu"
              onClick={this.showDrawer}
            ></Icon>
            <Drawer
              style={{ textAlign: "center" }}
              placement={this.state.placement}
              closable={true}
              onClose={this.onClose}
              visible={this.state.visible}
            >
              <Link to="/">
                <a style={{ fontSize: "20px" }}>
                  {window.i18n.translate("Home")}
                </a>
              </Link>
              <br /> <br />
              <a href="#middle">
                <a style={{ fontSize: "20px" }}>
                  {window.i18n.translate("HowItWorks")}
                </a>
              </a>
              <br /> <br />
              <a
                style={{ fontSize: "20px" }}
                href="https://www.verkoopnou.co.za/app/signup?type=dealer"
              >
                {window.i18n.translate("BeADealer")}
              </a>{" "}
              <br /> <br />
              <Link to="/contact-us">
                <a style={{ fontSize: "20px" }}>
                  {window.i18n.translate("ContactUs")}
                </a>
              </Link>
              <br /> <br />
              <a href="https://www.verkoopnou.co.za/app/signin?type=customer">
                <Button
                  style={{
                    fontSize: "19px",
                    margin: "auto",
                    marginTop: "1em",
                    height: "2.7em",
                    borderRadius: "3em",
                    width: "9em",
                  }}
                  type="primary"
                >
                  {window.i18n.translate("CustomerLogin")}
                </Button>
              </a>{" "}
              <br /> <br />
              <a href="https://www.verkoopnou.co.za/app/signin?type=dealer">
                <Button
                  style={{
                    margin: "auto",
                    fontSize: "19px",
                    background: "white",
                    color: "rgb(11, 149, 156)",
                    height: "2.7em",
                    borderRadius: "3em",
                    width: "11em",
                  }}
                  type="primary"
                >
                  {window.i18n.translate("DealerLogin")}
                </Button>
              </a>{" "}
              <div
                className="lang-radio-mobile"
                style={{
                  marginLeft: "0em",
                  marginTop: "1em",

                  bottom: 10,
                  left: 0,
                }}
              >
                <Radio.Group
                  style={{ margin: "auto", boxShadow: "none" }}
                  defaultValue={localStorage.locale}
                  onChange={this.handleLocaleSwitch}
                >
                  <Radio.Button
                    style={{
                      fontSize: "1.5em",
                      border: "none",
                      boxShadow: "none",
                      borderRight: "1px solid ",
                      backgroundColor: "transparent",
                    }}
                    value="af"
                  >
                    Afr
                  </Radio.Button>
                  <Radio.Button
                    style={{
                      zIndex: 99,
                      backgroundColor: "transparent",
                      fontSize: "1.5em",
                      border: "none",

                      marginLeft: "0.05em",
                    }}
                    value="en"
                  >
                    Eng
                  </Radio.Button>
                </Radio.Group>
              </div>{" "}
              <img src={logo} alt="Logo" className="verkoopnou-logo-menu" />
              <h1 className="verkoopnou-logo-text-menu">verkoopnou.co.za </h1>
            </Drawer>
            <TopHeader />{" "}
            <div
              className="lang-radio"
              style={{
                marginLeft: "1.6em",
                marginTop: "2.2em",
                position: "fixed",

                bottom: 10,
                left: -10,
              }}
            >
              <Radio.Group
                style={{ margin: "auto", boxShadow: "none" }}
                defaultValue={localStorage.locale}
                onChange={this.handleLocaleSwitch}
              >
                <Radio.Button
                  style={{
                    fontSize: "1.5em",
                    border: "none",
                    boxShadow: "none",
                    borderRight: "1px solid ",
                    backgroundColor: "transparent",
                  }}
                  value="af"
                >
                  Afr
                </Radio.Button>
                <Radio.Button
                  style={{
                    zIndex: 99,

                    backgroundColor: "transparent",
                    fontSize: "1.5em",
                    border: "none",

                    marginLeft: "0.05em",
                  }}
                  value="en"
                >
                  Eng
                </Radio.Button>
              </Radio.Group>
            </div>
          </div>
          <div
            className="home-div"
            style={{
              margin: "auto",
              top: "0em",
              border: "0px solid #8888882c",
              boxShadow: "none",
              maxWidth: "90em",
              zIndex: "0",
              height: "100vh",
            }}
          >
            <Card
              className="choose-car-card"
              style={{
                paddingTop: "2em",
                paddingBottom: "1em",
                textAlign: "center",
                top: "7%",
                backgroundColor: "transparent",
                border: "1px solid #00b3bcb0",
                boxShadow: "0px 4px 10px 2px rgba(0, 0, 0, 0.178)",
                borderRadius: 20,
                width: "37em",
                zIndex: "0",
              }}
            >
              <h1
                style={{ wordWrap: "nowrap", fontFamily: "unineue" }}
                className="home-content-text"
              >
                {window.i18n.translate("SellYour")}
                <br /> <br /> {window.i18n.translate("VehicleInA")}
                <br /> <br /> {window.i18n.translate("Flash")}
                <a href="https://www.verkoopnou.co.za/app/signup"></a>
              </h1>
              <Card
                className="home-select"
                style={{
                  backgroundColor: "transparent",
                  boxShadow: "none",
                  marginBottom: "-1.5em",
                }}
                value={this.state.year}
                id="year-card-head"
                title={window.i18n.translate("Year")}
                bordered={false}
              >
                <Select
                  style={{ textAlign: "left", width: "80%" }}
                  // showSearch
                  id="CarDetails"
                  className="ant-dropdown-car-details"
                  onChange={this.selectYear}
                  placeholder={
                    window.i18n.translate("Select") +
                    " " +
                    window.i18n.translate("Year")
                  }
                >
                  {this.state.years.map((year) => {
                    return <Option value={year}>{year}</Option>;
                  })}
                </Select>
              </Card>{" "}
              <Card
                className="home-select"
                style={{
                  backgroundColor: "transparent",
                  boxShadow: "none",
                  marginBottom: "-1.5em",
                }}
                id="brand-card-head"
                title={window.i18n.translate("Make")}
                bordered={false}
                value={this.state.make}
              >
                <Select
                  style={{
                    width: "80%",
                  }}
                  placeholder={
                    window.i18n.translate("Select") +
                    " " +
                    window.i18n.translate("Make")
                  }
                  //   showSearch
                  id="CarDetails"
                  className="ant-dropdown-car-details"
                  onChange={this.selectMake}
                >
                  {this.state.makes.map((make) => {
                    return <Option value={make}>{make}</Option>;
                  })}
                </Select>
              </Card>{" "}
              <Card
                className="home-select"
                style={{
                  backgroundColor: "transparent",
                  boxShadow: "none",
                  marginBottom: "1.5em",
                }}
                title={window.i18n.translate("Model")}
                bordered={false}
                value={this.state.model}
              >
                <Select
                  style={{
                    width: "80%",
                  }}
                  placeholder={
                    window.i18n.translate("Select") +
                    " " +
                    window.i18n.translate("Model")
                  }
                  //   showSearch
                  id="CarDetails"
                  overlayClassName="ant-dropdown-car-details"
                  className="ant-dropdown-car-details"
                  onChange={this.selectModel}
                >
                  {this.state.models.map((model) => {
                    return <Option value={model}>{model}</Option>;
                  })}
                </Select>
              </Card>
              <a
                href={
                  "https://www.verkoopnou.co.za/app/signup?year=" +
                  this.state.year +
                  "&make=" +
                  this.state.make +
                  "&model=" +
                  this.state.model
                }
              >
                {" "}
                <Button
                  className="sell-it-button-home"
                  disabled={
                    !this.state.make || !this.state.model || !this.state.year
                  }
                  type="primary"
                  style={{
                    backgroundColor: "#00b4bc",
                    color: "white",
                    width: "80%",
                    borderRadius: 90,
                    height: "2.7em",
                    margin: "auto",
                    fontSize: "29px",
                    fontWeight: 700,
                  }}
                >
                  {window.i18n.translate("SellIt")}
                </Button>
              </a>
            </Card>{" "}
            <img src={silverCar} alt="Logo" className="silver-car" />
            <img src={silverCarWheel} alt="Logo" className="silver-car-wheel" />
          </div>
        </div>
        <Content style={{ padding: "0 50px" }}>
          <div style={{ margin: "auto", maxWidth: "90em" }}>
            <img src={dotLine} alt="Logo" className="dot-line" />
            <img src={circleBig} alt="Logo" className="circle-big" />
            <img src={circleBig} alt="Logo" className="circle-big-1" />

            <div
              className="howa-it-works"
              style={{
                marginTop: "-3em",
                overflow: "hidden",
              }}
              id="middle"
            >
              <Row gutter={24}>
                <Col sm={24} md={12}>
                  <Fade duration={1100} delay={200} left cascade>
                    <h1
                      className="home-content-text-1"
                      style={{
                        fontSize: "43px",
                        marginTop: "-2em",
                        fontWeight: "700",
                      }}
                    >
                      {window.i18n.translate("HowItWorks")}
                    </h1>{" "}
                  </Fade>{" "}
                  <RubberBand delay={1300}>
                    <img
                      className="circle-numbers"
                      src={circle1}
                      alt="Logo"
                      style={{
                        position: "absolute",
                        marginLeft: "-3.1em",
                        bottom: "5.7em",
                        width: "2.5em",
                        fontSize: "24px",
                      }}
                    />{" "}
                  </RubberBand>
                  <Fade duration={1100} delay={500} right>
                    <h1
                      className="how-it-header"
                      style={{
                        fontSize: "24px",
                        fontWeight: 700,
                        marginTop: "12em",
                        marginLeft: "6em",
                      }}
                    >
                      {window.i18n.translate("RegisterYourCar")}
                    </h1>
                  </Fade>
                  <Fade duration={1500} delay={500} right>
                    <p
                      className="how-it-paragraph"
                      style={{
                        fontSize: "20px",
                        fontWeight: 400,
                        marginTop: "0em",
                        marginLeft: "7.2em",
                      }}
                    >
                      {window.i18n.translate("RegisterTheCarYouWantToSell")}
                      <br />{" "}
                      {window.i18n.translate("WithUpToDateDetailsOfTheCar")}
                      <br />
                      {window.i18n.translate("AndGetInstantQuoteOfBasedOn")}
                      <br /> {window.i18n.translate("TheValueOfTheCar")}
                      <br />
                    </p>
                  </Fade>
                </Col>
                <Col span={12}>
                  <img
                    src={soldVehicles}
                    alt="Logo"
                    className="sold-vehicles"
                  />
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={12}>
                  <img src={statPic} alt="Logo" className="stats-pic" />
                </Col>
                <Col md={12}>
                  <RubberBand delay={1300}>
                    <img
                      className="circle-numbers-2"
                      src={circle2}
                      alt="Logo"
                      style={{
                        position: "absolute",
                        marginLeft: "5.85em",
                        bottom: "8em",
                        width: "4.3em",
                      }}
                    />
                  </RubberBand>
                  <h1
                    className="receive-header"
                    style={{
                      fontSize: "24px",
                      fontWeight: 700,
                      marginTop: "12em",
                      marginLeft: "7em",
                    }}
                  >
                    <Fade duration={1100} delay={500} right>
                      {window.i18n.translate("ReceiveAndVetOffers")}
                    </Fade>
                  </h1>
                  <Fade duration={1500} delay={500} right>
                    <p
                      className="receive-paragraph"
                      style={{
                        fontSize: "20px",
                        fontWeight: 400,
                        marginTop: "0em",
                        marginLeft: "8.4em",
                      }}
                    >
                      {window.i18n.translate("GetCustomersFromDifferentPlaces")}
                      <br /> {window.i18n.translate("ToMakeYouTheBestOffer")}
                      <br />{" "}
                      {window.i18n.translate(
                        "ScreenOffersUsingPreferredCriteria"
                      )}
                      <br />
                    </p>
                  </Fade>
                </Col>
              </Row>{" "}
              <Row gutter={24}>
                <Col md={12}>
                  <h1
                    className="paid-header"
                    style={{
                      fontSize: "24px",
                      fontWeight: 700,
                      marginTop: "11em",
                      marginLeft: "6em",
                    }}
                  >
                    <RubberBand delay={1300}>
                      <img
                        className="circle-numbers-3"
                        src={circle3}
                        alt="Logo"
                        style={{
                          position: "absolute",
                          marginLeft: "-3.14em",
                          bottom: "4.6em",
                          width: "2.5em",
                        }}
                      />
                    </RubberBand>
                    <Fade duration={1100} delay={500} right>
                      {window.i18n.translate("GetPaid")}
                    </Fade>
                  </h1>
                  <Fade duration={1500} delay={500} right>
                    <p
                      className="paid-paragraph"
                      style={{
                        fontSize: "20px",
                        fontWeight: 400,
                        marginTop: "0em",
                        marginLeft: "7.2em",
                      }}
                    >
                      {window.i18n.translate("SelectTheBestOfferFromTheBids")}{" "}
                      <br />
                      {window.i18n.translate(
                        "YouHaveReceivedAndGetYourCash"
                      )}{" "}
                      <br />
                      {window.i18n.translate("InTheBank")}
                    </p>{" "}
                  </Fade>
                </Col>
                <Col span={12}>
                  <img
                    src={moneyTransfer}
                    alt="Logo"
                    className="money-transfer-pic"
                  />
                </Col>
              </Row>
              <div
                className="now-you-are-div"
                style={{
                  textAlign: "center",
                }}
              >
                {" "}
                <Fade duration={1100} delay={200} left cascade>
                  <h1
                    className="now-you-are"
                    style={{
                      fontSize: "37px",
                      fontWeight: 700,
                      marginTop: "7.5em",
                      textAlign: "center",
                      color: "#00B4BC",
                    }}
                  >
                    {window.i18n.translate("NowYouAreSet")}
                  </h1>{" "}
                </Fade>
                <Fade duration={1100} delay={200} left cascade>
                  <p
                    style={{
                      fontSize: "20px",
                      fontWeight: 400,
                      marginTop: "-1em",
                    }}
                  >
                    {window.i18n.translate("SignUpAndStartSelling")}
                  </p>{" "}
                </Fade>
                <Flip left cascade>
                  <a href="https://www.verkoopnou.co.za/app/signup?type=customer">
                    <Button
                      style={{ marginTop: "0.5em" }}
                      id="loginButtons"
                      className="dealer-buttons"
                      type="primary"
                    >
                      {window.i18n.translate("SellIt")}
                    </Button>
                  </a>
                </Flip>
              </div>
            </div>
          </div>
          <BottomFooter />
          <Icon
            className="facebook-icon-mobile"
            type="facebook"
            theme="filled"
            style={{ paddingRight: "0em", fontSize: "2em", color: "#00888E" }}
            url="https://www.facebook.com/verkoopnou/"
          />
        </Content>
      </React.Fragment>
    );
  }
}

export default SalesScreen;
