export default {
  WelcomeBack: "Welcome back",
  SignInHeading: "Sign in on Verkoopnou",
  Welcome: "Welcome",
  CreateAccountHeading: "Create a new account",
  Dealer: "Dealer",
  Customer: "Customer",
  SignIn: "Sign In",
  SignOut: "Sign Out",
  SignUp: "Sign Up",
  Login: "Login",
  WithGoogle: "with Google",
  WithFacebook: "with Facebook",
  Name: "Name",
  DealerUsername: "Dealer Username",
  Username: "Username",
  Password: "Password",
  Forgot: "Forgot password?",
  AlreadyRegistered: "Already Registered",
  RegisterNow: "Register now to start your journey!",
  ContinueSubHeading: "to continue",
  EnterName: "Enter first name",
  EnterLastName: "Enter last name",
  EnterEmail: "Enter valid email address",
  EnterCellNumber: "Enter cell number",
  EnterPassword: "Enter password",
  BidAmount: "Bid Amount",
  Confirm: "Confirm",
  Accept: "Accept",
  TermsConditions: "Terms & Conditions?",
  WelcomeToHeading:
    "Welcome to Verkoopnou, a safe space to sell your car to credible dealers",
  VettedByHeading: "(vetted by us) for the best prices.",
  WithVerkoopnouHeading:
    "With Verkoopnou, selling your car is as easy as can be.",
  ReceiveBids: "Receive bids for your car in an",
  SellingReason: "Selling Reason",
  OnlineAuction: "online auction",
  AcceptBids: "Accept the highest bid",
  AndYour: "and your car is sold!",
  TellUs: "Tell us about your car and",
  HowMuch: "how much you want to sell it for",
  GoTo: "Go to",
  MyCars: "My Cars",
  AddCar: "Add a car",
  ToSell: "to sell",
  Step: "Step",
  GeneralInformation: "General information",
  Year: "Year",
  Make: "Make",
  Model: "Model",
  ModelType: "Model type",
  Type: "Type",
  Transmission: "Transmission",
  Mileage: "Mileage",
  Colour: "Colour",
  FuelType: "Fuel type",
  OptionalExtras: "Optional extras",
  Province: "Province",
  CityTown: "City/Town",
  WhereDidYou: "Where did you buy your vehicle?",
  ReasonFor: "Reason for",
  Selling: "selling",
  IncludingYouText: "Including you, how many owners did the vehicle have?",
  LicenseExpiry: "License expiry date",
  AcceptedVehicles: "Accepted Vehicles",
  AuctionsWon: "Auctions Won",
  AuctionsLost: "Auctions Lost",
  AdditionalComment: "Additional comment",
  Optional: "(Optional)",
  DamageAssessment: "Damage Assessment",
  Complete1: "Complete",
  CompleteDeal: "Complete Deal",
  ToUnlock: "to unlock",
  ServiceHistory: "Service History and Finance",
  Outstanding: "Outstanding",
  TyreCondition: "Tyre Condition",
  VehicleCondition: "Vehicle Condition",
  VehicleDamage: "Vehicle Damage",
  DamageRepair: "Damage Repair",
  VehicleImages: "Vehicle Images",
  Vehicle: "Vehicle",
  Automatic: "Automatic",
  Manual: "Manual",
  Electric: "Electric",
  Hybrid: "Hybrid",
  AskingPrice: "Asking Price",
  SendToAuction: "Send to Auction",
  SentToAuction: "Sent to Auction",
  CurrentDay: "Current Day",
  ExportToPDF: "Export to PDF",
  Copyright: "Copyright",
  Select: "Select",
  EditHistory: "Edit History",
  an: "an",
  option: "option",
  Other: "Other",
  SunRoof: "Sunroof",
  Eg: "eg.",
  Provide: "Provide",
  NumberOfOwners: "Number of owners",
  IDontKnow: "i don't know",
  Date: "Date",
  Dealer: "Dealer",
  Private: "Private",
  Auction: "Auction",
  EmailRequired: "a Validm Email is required",
  UsernameRequired: "Username required",
  NameRequired: "Name required",
  SurnameRequired: "Surname required",
  NumberRequired: "a Valid number is required",
  PasswordRequired: "Password required",
  NewPasswordRequired: "New Password required",
  UpToDate: "Not sure but up to date",
  Notes: "Notes",
  ButExpired: "Not sure but expired",
  SelectedDate: "As per selected date",
  Bad: "Bad",
  Good: "Good",
  Exellent: "Exellent",
  SpareKeys: "Do you have spare keys for your vehicle?",
  SpareKeys1: "Spare Keys",
  Yes: "Yes",
  No: "No",
  NumberSpareKeys: "Number of spare keys",
  MechanicalIssues: "Does your vehicle have any mechanical issues currently?",
  MechanicalIssues1: "Mechanical Issues",
  IfYes: "If Yes, please provide details",
  InteriorCondition: "Interior Condition",
  Rating: "Rating",
  Average: "Average",
  EverBeenInAnAccident:
    "Has your vehicle ever been in an accident, repaired, or had any spray work to fix scratches or any hail damage?",
  HailDamage: "Hail Damage",
  Accident: "Accident",
  CosmeticSpraywork: "Cosmetic Spraywork to Fix scratches",
  WindscreenCracks: "Does the windscreen have any cracks?",
  WindscreenCracks1: "Windscreen Cracks",
  CanBeFixed: "Can be fixed",
  NeedsReplaced: "Needs to be replaced",
  RimsScratched: "Are the mags or rims scratched, dented or buckled?",
  MagsRimsDamage: "Mags/Rims Damage",
  MyServiceHistory: "Service History",
  PrivateWorkshops: " and Private Workshops",
  FullServiceHistory: "Full Service History with",
  PartialServiceHistory: "Partial Service History",
  NoServiceHistory: "No Service History",
  Agents: "Agents",
  VehicleFinanced: "Is your vehicle financed?",
  Finance: "Finance",
  AmountOutstanding: "Amount outstanding",
  Amount: "Amount",
  ServicePlan: "Does your vehicle have a service/maintenance plan?",

  ServiceMaintenance: "Service/Maintenance plan",
  FrontTyres: "Front Tyres",
  BackTyres: "Back Tyres",
  TyresAreNew: "Tyres are new",
  New: "New",
  New1: "New",
  TyresAre: "Tyres are",

  Worn: "Half Worn",
  HalfWorn: "Half Worn",
  DamageOrBodyWork:
    "Does the vehicle have any damage and / or previous body work done?",
  ClosestMatch: "Select the closest match to your vehicle.",
  Hatchback: "Hatchback",
  VanCombi: "Van/Combi",
  TypeOfDamage:
    "Select the type of damage to add and drag the dot to the relevant location.",
  SprayworkDone: "Spraywork Done",
  Scratch: "Scratch",
  Chips: "Chip(s)",
  Dents: "Dent(s)",
  Rust: "Rust",
  Hail: "Hail",
  Cracked: "Cracked",
  Broken: "Broken",
  RemoveAll: "Remove All",
  MainViewImageRequired:
    "Main view image is required. The more images you add the better price you will get.",
  MainView: "Main View",
  FrontView: "Front View",
  LeftView: "Left View",
  RightView: "Right View",
  RearView: "Rear View",
  Interior: "Interior",
  Engine: "Engine",
  Boot: "Boot",
  ServiceBook: "Service book",
  Remove: "Service book",
  Stamped: "(Stamped)",
  UploadImage: "Upload Image",
  TakeImage: "Take Image",
  GiveIndication:
    "Please give an indication of the amount you would like for this vehicle.",
  AlreadySent: "Already sent to auction",
  Review: "Review",
  CloseReview: "Close Review",
  VehicleBought: "Vehicle Bought",
  Owners: "Owners",
  Warranty: "Warranty",
  RearTyres: "Rear Tyres",
  FrontTyres: "Front Tyres",
  NoDamage: "No Damage",
  Damage: "Damage",
  White: "White",
  Black: "Black",
  Red: "Red",
  Blue: "Blue",
  Green: "Green",
  Grey: "Grey",
  Champagne: "Champagne",
  Silver: "Silver",
  Yellow: "Yellow",
  Orange: "Orange",
  Brown: "Brown",
  Burgandy: "Burgandy",
  AddVehicleDetails: "Add Vehicle Details",
  VehicleDetails: "Vehicle Details",
  Cancel: "Cancel",
  Incomplete: "Incomplete",
  Complete: "Complete",
  Progress: "Progress",
  Reset: "Reset",
  Completion: "Completion",
  YourNotifications: "Your Notifications",
  ApproveVehicle: "Approve Vehicle",
  UserAddedVehicle: "User Added a Vehicle",
  AdminApprovedYourVehicles: "Admin has approved one of your vehicles",
  AdminDeletedYourVehicles: "Admin has deleted one of your vehicles",
  AdminApprovedDealerAccount: "Admin has approved your dealer account.",
  AdminRejectedYourVehicles: "Admin has rejected one of your vehicles",
  AdminRejectedDealerAccount: "Admin has rejected your dealer account.",
  AdminApprovedAuctionHistoryVehicles:
    "Admin has approved one of your auction History Vehicles",
  AdminRejectedAuctionHistoryVehicles:
    "Admin has rejected one of your auction History Vehicles",
  CustomerRrejectedWinningBidOffer:
    "A customer has rejected a winning bid offer.",
  CustomerRrejectedYourOffer: "A customer has rejected your bid offer.",
  CustomerRrejectedOffer: "A customer has rejected your offer.",
  VehicleSubmittedForReview: "A vehicle was submitted for review",
  VehicleSentreViewSuccesfully:
    "Vehicle sent to review for auction succesfully",
  AdminMadeChangesToVehicle: "Admin has made an offer on one of your vehicles",
  AdminMadeChangesToVehicle: "Admin has made changes to one of your vehicles",
  AdminActivatedDealerAccount: "Admin has activated your dealer account.",
  AdminDectivatedDealerAccount: "Admin has deactivated your dealer account.",
  YourVehicleWasSubmittedForReviewToBePlacedOnAuction:
    "Your vehicle was submitted for review to be placed on auction",
  YouWillBeNotifiedOnceThisIsDone: "You will be notified once this is done.",
  SuccessfullyDeactivatedDealer: "Successfully deactivated dealer",
  SuccessfullyDeactivatedDealerPrices: "Successfully deactivated dealer prices",
  SuccessfullyActivatedDealerPrices: "Successfully activated dealer prices",
  SuccessfullyActivatedDealer: "Successfully activated dealer",
  SuccessfullyDownloadedDocument: "Successfully downloaded document",
  SuccessfullyDeletedUser: "Successfully deleted user",
  SuccessfullyDeletedDealer: "Successfully deleted dealer",
  SuccessfullyApprovedDealer: "Successfully approved dealer",
  AddNote: "Add Note",
  ClearAll: "Clear All",
  Image: "Image",
  Images: "Images",
  Status: "Status",
  Bidding: "Bidding",
  Action: "Action",
  MyVehicles: "My Vehicles",
  AddVehicle: "Add Vehicle",
  EditVehicle: "Edit Vehicle",
  DeleteVehicle: "Delete Vehicle",
  Delete: "Delete",
  Approved: "Approved",
  Reject: "Rejected",
  Approve: "Approve",
  Reject: "Reject",
  AwaitingApproval: "Awaiting Approval",
  AwaitingResponse: "Awaiting Response",
  AwaitingCompletion: "Awaiting Completion",
  AwaitingSubmission: "Awaiting Submission",
  AwaitingOfferReview: "Awaiting Offer Review",
  OfferRejected: "Offer Rejected",
  ViewOffer: "View Offer",
  OfferAccepted: "Offer Accepted",
  AreYouSureDeleteVehicle: "Are you sure you want to delete this vehicle?",
  VehiclesOnAuction: "Vehicles on Auction",
  Trade: "Trade",
  Bids: "Bids",
  NoBids: "No Bids",
  WinningBid: "WinningBid",
  WinningBidder: "WinningBidder",
  FirstBidder: "First Bidder",
  YourBids: "Your Bid",
  YourBid: "Your Bid",
  Location: "Location",
  OfferWonAmount: "Offer/Won Amount",
  LastFollowUp: "Last Follow-up",
  AcceptedPrice: "Accepted Price",
  MMCode: "M&M Code",
  Condition: "Condition",
  NotProvided: "Not Provided",
  TradePrice: "Trade Price",
  RetailPrice: "Retail Price",
  NewPrice: "New Price",
  PreviousBodyWork: "Previous Body Work/Accident",
  Deal: "Deal",
  General: "General",
  Recon: "Recon",
  MyBasket: "My Basket",
  AccountSettings: "Account Settings",
  Account: "Account",
  Notification: "Notification",
  FirstName: "First Name",
  Surname: "Surname",
  Nickname: "Nickname",
  Number: "Number",
  AlternativeNumber: "Alternative Number",
  WheredidYouHearAboutUs: "Where did you hear about us?",
  SelectPlace: "Select a place",
  BillboardOrStreetpoleAd: "Billboard or Streetpole ad",
  WordOfMouth: "Word of Mouth",
  SaveChanges: "Save Changes",
  SaveVehicle: "Save Vehicle",
  MySettings: "My Settings",
  UploadProfileImage: "Upload Profile Image",
  Edit: "Edit",
  Or: "or",
  ManageVehicles: "Manage Vehicles",
  CustomerAskingPrice: "Customer Asking Price",
  ReservePrice: "Reserve Price",
  Client: "Client",
  ClientName: "Client Name",
  ClientSurname: "Client Surname",
  ClientNumber: "Client Number",
  City: "City",
  DealComplete: "Deal Complete",
  OnAuction: "On Auction",
  AdminOfferAccepted: "Admin Offer Accepted",
  AdminOfferRejected: "Admin Offer Rejected",
  DealerOfferAccepted: "Dealer Offer Accepted",
  DealerOfferRejected: "Dealer Offer Rejected",
  AwaitingOfferResponse: "Awaiting Offer Response",
  ManageAuction: "Manage Auction",
  RemoveFromAuction: "Remove from auction",
  PutOnAuction: "Put on auction",
  AuctionAllVehicles: "Auction All Vehicles",
  AddSelectedVehicles: "Add Selected Vehicles",
  RemoveSelectedVehicles: "Remove Selected Vehicles",
  HighestBid: "Highest Bid",
  Start: "Start",
  End: "End",
  TimeLeft: "Time Left",
  Calendar: "Calendar",
  Vehicles: "Vehicles",
  Active: "Active",
  AuctionActive: "Auction Active",
  AuctionClosed: "Auction Closed",
  Closed: "Closed",
  Month: "Month",
  Search: "Search",
  AuctionHistory: "Auction History",
  To: "to",
  FollowedUp: "Followed Up",
  Details: "Details",
  ViewDetails: "View Details",
  ViewBidInfo: "View Bid Info",
  PutOnAuctionList: "Put on Auction List",
  AcceptOfferCustomer: "Accept Offer for Customer",
  DealDone: "Deal Done",
  ReAuction: "Re-Auction",
  DealOffersSent: "Deal Offers Sent",
  DealOffersAccepted: "Deal Offers Accepted",
  DealFailed: "Deal Failed",
  DealDoneWithDealer: "Deal Done with Dealer",
  DealNotDoneWithDealer: "Deal not Done with Dealer",
  DealAmount: "Deal Amount",
  Invoice: "Invoice",
  VerkoopnouOfferAccepted: "Verkoopnou Offer Accepted",
  Never: "Never",
  ManagePeople: "Manage People",
  HeardFrom: "Heard From",
  Dealers: "Dealers",
  NotUploaded: "Not Uploaded",
  PricesActive: "Prices Active",
  ActivatePrices: "Activate Prices",
  Deactivate: "Deactivate",
  Activate: "Activate",
  DeactivatePrices: "Deactivate Prices",
  SetReservePrice: "Set Reserve Price",
  MakeAnOffer: "Make an Offer",
  RejectVehicle: "Reject Vehicle",
  ClientUsername: "Client Username",
  DealerName: "Dealer Name",
  DealerSurname: "Dealer Surname",
  DealerNumber: "Dealer Number",
  DealerPrice: "Deal Price",
  AmountLikeToOffer: "What is the amount you would like to offer?",
  AmountLikeToReserve: "What is the amount you would like to reserve?",

  Home: "Home",
  HowItWorks: "How it works",
  ContactUs: "Contact us",
  CustomerLogin: "Customer login",
  DealerLogin: "Dealer login",
  CustomerLogin: "Customer login",
  BeADealer: "Become a dealer",
  Help: "Help",
  PrivacyPolicy: "Privacy Policy",
  Powered: "Powered By",
  SellYour: "SELL YOUR",
  VehicleInA: "VEHICLE IN A",
  Flash: "FLASH",
  Year: "Year",
  Make: "Make",
  Model: "Model",
  Select: "Select",
  SellIt: "Sell it",
  RegisterYourCar: "Register your car",
  RegisterTheCarYouWantToSell: "Register the car you want to sell",
  WithUpToDateDetailsOfTheCar: "with up-to-date details of the car",
  AndGetInstantQuoteOfBasedOn: "and get instant quote of based on",
  TheValueOfTheCar: "the value of the car.",
  ReceiveAndVetOffers: "Receive and Vet Offers",
  GetCustomersFromDifferentPlaces: "Get customers from different places",
  ToMakeYouTheBestOffer: "to make you the best offer.",
  ScreenOffersUsingPreferredCriteria: "Screen offers using preferred criteria.",
  GetPaid: "Get paid",
  SelectTheBestOfferFromTheBids: "Select the best offer from the bids",
  YouHaveReceivedAndGetYourCash: "you have received and get your cash ",
  InTheBank: "In the bank",
  NowYouAreSet: "Now you are set!",
  SignUpAndStartSelling: "Sign up and start selling",
  SendUsAMessage: "Send us a message",
  YourName: "Your Name",
  EmailAddress: "Email Address",
  PhoneNumber: "Phone Number",
  Company: "Company",
  Message: "Message",
  Send: "Send",
  ContactInformation: "Contact Information",
};
