import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import "./index.css";
import SalesScreen from "./Pages/sales-screen";
import ContactUs from "./Pages/contact-us";
import I18n from "./i18n/i18n";
import * as serviceWorker from "./serviceWorker";
localStorage.locale = "af";
ReactDOM.render(
  <BrowserRouter>
    <I18n />
    <Switch>
      <Route exact path="/" component={SalesScreen} />
      <Route exact path="/contact-us" component={ContactUs} />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
