import { Component } from "react";
import english from "./en";
import afrikaans from "./af";

export const Translations = {
  en: english,
  af: afrikaans,
};

class I18n extends Component {
  constructor(props) {
    super(props);
    window.i18n = this;
  }
  translate(message) {
    let locale = localStorage.locale;
    // We're actually asking for 'something' to be translated
    if (message) {
      // The translation exists AND the message exists in this translation
      if (Translations[locale] && Translations[locale][message]) {
        return Translations[locale][message];

        // Otherwise try in the default translation
      } else if (Translations["af"] && Translations["af"][message]) {
        return Translations["af"][message];
      }
    }

    return "???";
  }
  render = () => {
    return null;
  };
}


export default I18n;
